import React, { useContext, useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Table from "../../components/table/Table"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/GlobalContextProvider"
import { useCollection } from "react-firebase-hooks/firestore"
import useFirebase from "../../firebase/useFirebase"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { Actions } from "../../context/reducer"
import ProjectList from "../../components/project-list/ProjectList"
import SpinLoading from "../../components/loading/SpinLoading"
import { State } from "../../context/State"
import useURLVariables from "../../helper/useURLVariables"
import TableSettingsButton from "../../components/table/TableSettingsButton"
import TableSettingsModal from "../../components/modals/TableSettingsModal"
import TableSettings from "../../components/table/TableSettings"

const IndexPage = () => {
  const firebase = useFirebase()
  const state: State = useContext<any>(GlobalStateContext)
  const dispatch: any = useContext(GlobalDispatchContext)
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false)
  const [loadingProjects, setLoadingProjects] = useState(true)

  const [snapshot, loading, error] = useCollection(
    firebase && state.user
      ? firebase
          .firestore()
          .collection("projects")
          .where("users", "array-contains", state.user.uid)
      : null
  )
  // === Code for if auto-loading projects is re-added ===
  // const vars = useURLVariables()
  // const projectKey = vars.project

  useEffect(() => {
    const updateData = async () => {
      if (!loading && firebase && snapshot) {
        const projects = snapshot.docs.map(doc => {
          return doc.data()
        })
        // === Code for if auto-loading projects is re-added ===
        // if (projectKey) {
        //   const promises = projects.map(async project => {
        //     if (project.key === projectKey) {
        //       await dispatch({ type: Actions.SetSelectedProject, project })
        //     }
        //   })
        //   await Promise.all(promises)
        //   console.log(state)
        // }
        await dispatch({ type: Actions.UpdateProjects, projects })
        setLoadingProjects(false)
      }
    }

    updateData()
  }, [snapshot, loading, error])

  useEffect(() => {
    if (state.user !== undefined) {
      if (!state.user) navigate("login")
      else setHasCheckedAuth(true)
    }
  }, [state])

  return (
    <>
      <SEO title="Dashboard" />
      {hasCheckedAuth && !loadingProjects ? (
        <Layout>
          {!state.selectedProject ? (
            <ProjectList projects={state.projects} />
          ) : (
            <>
              <Table />
              <TableSettings />
            </>
          )}
        </Layout>
      ) : (
        <SpinLoading type="fullscreen" />
      )}
    </>
  )
}

export default IndexPage
