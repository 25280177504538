import jsPDF from "jspdf"
import imageToBase64 from "./util/imageToBase64"
import { Project } from "../../context/State"
import { HeaderImage } from "./constraints/Header"

const addHeaderImage = async (
  pdf: jsPDF,
  project: Project
): Promise<number> => {
  return new Promise((resolve, reject) => {
    if (!project.image) resolve(0)
    var xhr = new XMLHttpRequest()
    xhr.responseType = "blob"
    xhr.onload = event => {
      const blob = xhr.response

      if (typeof document === undefined) {
        console.log("Document does not exist")
        return
      }

      imageToBase64(blob, blob.type === "image/svg+xml", imageSource => {
        const img = new Image()
        img.addEventListener(
          "load",
          () => {
            const w = img.width
            const h = img.height
            const ratio = w / h

            let pdfImageType
            if (blob.type === "image/png") pdfImageType = "PNG"
            if (blob.type === "image/jpg") pdfImageType = "JPEG"
            if (blob.type === "image/svg+xml") pdfImageType = "SVG"

            const HeaderImageWidth = HeaderImage.height * ratio
            if (pdfImageType !== "SVG") {
              pdf.addImage(
                imageSource,
                pdfImageType,
                HeaderImage.x,
                HeaderImage.y,
                HeaderImageWidth,
                HeaderImage.height
              )
            }

            resolve(HeaderImageWidth)
          },
          { once: true }
        )
        img.src = imageSource as string
      })
    }

    xhr.open("GET", project.image as string)
    xhr.send()
  })
}

export default addHeaderImage
