import PDF from "./PDF"
import Header from "./Header"
import Footer from "./Footer"

const Body = {
  start: {
    x: PDF.start.x,
    y: Header.end.y,
  },
  end: {
    x: PDF.end.x,
    y: Footer.start.y,
  },
  padding: {
    pageOne: {
      x: PDF.margin,
      y: PDF.margin / 2,
    },
    otherPages: {
      x: PDF.margin,
      y: 0,
    },
  },
}

export const BodyContent = {
  start: {
    getX: (page: number) => {
      return (
        PDF.start.x +
        (page === 0 ? Body.padding.pageOne.x : Body.padding.otherPages.x)
      )
    },
    getY: (page: number) => {
      return (
        Header.end.y +
        (page === 0 ? Body.padding.pageOne.y : Body.padding.otherPages.y)
      )
    },
  },
  end: {
    getX: (page: number) => {
      return (
        PDF.end.x -
        (page === 0 ? Body.padding.pageOne.x : Body.padding.otherPages.x)
      )
    },
    getY: (page: number) => {
      return (
        Footer.start.y -
        (page === 0 ? Body.padding.pageOne.y : Body.padding.otherPages.y)
      )
    },
  },
  title: {
    marginBottom: 0.05,
  },
  vote: {
    marginBottom: 0.25,
  },
}

export default Body
