import { Project, VoteEvent } from "../../context/State"
import jsPDF from "jspdf"
import { averageRatings } from "../Ratings"
import PDF from "./constraints/PDF"
import addFonts from "./addFonts"
import addFooter from "./addFooter"
import addHeaderImage from "./addHeaderImage"
import addHeaderTitle from "./addTitle"
import { BodyContent } from "./constraints/Body"
import splitTextToSize from "./util/splitTextToSize"
import { standardDeviation } from "simple-statistics"

export interface PDFOptions {
  type: "banner-and-image" | "printer-friendly"
  font: "Trebuchet-MS" | "Helvetica" | "Times" | "Roboto"
}

const exportProjectAsPDF = async (
  project: Project,
  voteEvents: VoteEvent[],
  options: PDFOptions
) => {
  return new Promise(async resolve => {
    const pdf = new jsPDF({ format: "letter", unit: "in" })
    addFonts(pdf)

    addFooter(pdf)

    let imageWidth = 0
    if (options.type === "banner-and-image") {
      pdf.setFillColor(project.backgroundColor)
      pdf.rect(PDF.start.x, PDF.start.y, PDF.end.x, PDF.margin, "F")
      imageWidth = await addHeaderImage(pdf, project)
    }

    addHeaderTitle(pdf, project, imageWidth, options)

    let page = 0
    let x = BodyContent.start.getX(page)
    let y = BodyContent.start.getY(page)

    pdf.setTextColor("#000000")
    voteEvents
      .sort((a, b) => {
        const aAverage = averageRatings(a.ratings)
        const bAverage = averageRatings(b.ratings)

        if (a.ratings.length > 0 && b.ratings.length === 0) return -1000
        if (a.ratings.length === 0 && b.ratings.length > 0) return 1000
        return aAverage - bAverage
      })
      .forEach(vote => {
        pdf.setFontSize(14)
        const name = splitTextToSize(
          pdf,
          vote.name,
          BodyContent.end.getX(page) - BodyContent.start.getX(page)
        )
        const dimensionsName = pdf.getTextDimensions(name as any)

        pdf.setFontSize(12)
        const author = splitTextToSize(
          pdf,
          vote.author,
          BodyContent.end.getX(page) - BodyContent.start.getX(page)
        )
        const dimensionsAuthor = pdf.getTextDimensions(author as any)

        if (
          y + dimensionsName.h + dimensionsAuthor.h >
          BodyContent.end.getY(page)
        ) {
          pdf.addPage()
          page += 1
          y = BodyContent.start.getY(page)
          addFooter(pdf)
        }

        // Add Ratings
        pdf.setFontSize(14)
        pdf.setFont(options.font, "bold")
        const ratingsText = averageRatings(vote.ratings).toString()
        const dimensionsRatings = pdf.getTextDimensions(ratingsText)
        pdf.text(ratingsText, BodyContent.end.getX(page), y, {
          align: "right",
        })

        // Add Standard Deviation
        pdf.setFontSize(12)
        pdf.setFont(options.font, "normal")
        const stdDev =
          Math.round(
            standardDeviation(vote.ratings.map(rating => rating.value)) * 1000
          ) / 1000
        pdf.text(
          "σ = " + stdDev.toString(),
          BodyContent.end.getX(page),
          y + dimensionsRatings.h + 0.05,
          {
            align: "right",
          }
        )

        // Add Title
        pdf.setFontSize(14)
        pdf.setFont(options.font, "bold")
        pdf.text(name, x, y)
        y += dimensionsName.h + BodyContent.title.marginBottom

        // Add Author
        pdf.setFontSize(12)
        pdf.setFont(options.font, "normal")
        pdf.text(author, x, y)
        y += dimensionsAuthor.h + BodyContent.vote.marginBottom
      })

    pdf.save(project.name.replace(/[^a-z0-9]/gi, "_") + ".pdf")
    resolve()
  })
}

export default exportProjectAsPDF
