import jsPDF from "jspdf"
import { FooterContent } from "./constraints/Footer"

const addFooter = (pdf: jsPDF) => {
  pdf.setFontSize(12)
  pdf.setFont("Trebuchet-MS", "normal")
  pdf.text(
    `https://www.primavote.app`,
    FooterContent.start.x,
    FooterContent.start.y
  )
}

export default addFooter
