import jsPDF from "jspdf"
import TrebuchetMS from "../../fonts/trebuchet-ms-normal"
import TrebuchetMSBold from "../../fonts/trebuchet-ms-bold"
import Roboto from "../../fonts/Roboto-normal"
import RobotoBold from "../../fonts/Roboto-bold"

const addFonts = (pdf: jsPDF) => {
  pdf.addFileToVFS("Trebuchet-MS.ttf", TrebuchetMS)
  pdf.addFont("Trebuchet-MS.ttf", "Trebuchet-MS", "normal")

  pdf.addFileToVFS("Trebuchet-MS-Bold.ttf", TrebuchetMSBold)
  pdf.addFont("Trebuchet-MS-Bold.ttf", "Trebuchet-MS", "bold")

  pdf.addFileToVFS("Roboto-Medium.ttf", Roboto)
  pdf.addFont("Roboto-Medium.ttf", "Roboto", "normal")

  pdf.addFileToVFS("Roboto-Bold.ttf", RobotoBold)
  pdf.addFont("Roboto-Bold.ttf", "Roboto", "bold")
}

export default addFonts
