import jsPDF from "jspdf"

const splitTextToSize = (pdf: jsPDF, text: string, maxLength: number) => {
  const charWidths = pdf.getCharWidthsArray(text)
  const charArray = text.split("")

  const lineArray = []
  let word = ""
  let line = ""
  let lineLength = 0
  let lastAddedIndex = 0
  for (let i = 0; i < charArray.length; i += 1) {
    console.log(lineLength, maxLength)
    const char = charArray[i]
    const length = charWidths[i]

    // If the line has exceeded max length
    if (lineLength >= maxLength * 4) {
      i = lastAddedIndex
      lineArray.push(line)
      word = ""
      line = ""
      lineLength = 0
    }

    // If the character is a space and the line is still under the max length
    else if (char === " ") {
      line += word + " "
      word = ""
      lastAddedIndex = i
    } else {
      lineLength += length
      word += char
    }
  }
  if (word !== "") line += word
  if (line !== "") lineArray.push(line)

  return lineArray
}

export default splitTextToSize
