import React, { useState, useContext } from "react"
import { Modal, Form, Input, message, Button, Radio } from "antd"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import "./NewProjectModal.scss"
import useFirebase from "../../firebase/useFirebase"
import { Project, State, VoteEvent } from "../../context/State"
import exportProjectAsPDF, {
  PDFOptions,
} from "../../helper/pdf/exportProjectAsPDF"

interface Props {
  project: Project | undefined
  visible: boolean
  setVisible: (value: boolean) => void
}

const fieldsDefault = [
  { name: "type", value: "banner-and-image" },
  { name: "font", value: "Trebuchet-MS" },
]

const CreatePDFModal = ({ project, visible, setVisible }: Props) => {
  const dispatch: any = useContext(GlobalDispatchContext)
  const state: State = useContext<any>(GlobalStateContext)
  const firebase = useFirebase()
  const [fields, setFields] = useState(fieldsDefault)
  const [previewFont, setPreviewFont] = useState("Trebuchet MS")
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setFields(fieldsDefault)
    setLoading(false)
    setVisible(false)
  }

  const handleOk = async () => {
    if (!firebase) return
    if (project) {
      setLoading(true)

      const snapshot = await firebase
        .firestore()
        .collection("projects")
        .doc(project.key)
        .collection("proposals")
        .get()

      let voteEvents: VoteEvent[] = []
      snapshot.forEach(doc => {
        voteEvents.push(doc.data() as VoteEvent)
      })
      exportProjectAsPDF(project, voteEvents, {
        type: fields[0].value as PDFOptions["type"],
        font: fields[1].value as PDFOptions["font"],
      }).then(() => {
        setLoading(false)
        setVisible(false)
        message.success("Success! Your project has been exported.")
      })
    } else {
      message.error("Sorry, something went wrong. Try again in a moment.")
    }
  }

  return (
    <Modal
      title={`Export as PDF`}
      centered
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Export
        </Button>,
      ]}
    >
      <Form
        name="add-poposal"
        fields={fields}
        initialValues={fieldsDefault}
        onFieldsChange={(changedFields, allFields) => {
          setFields(allFields as any)
        }}
      >
        <Form.Item
          className="add-proposal__label"
          label="Export Type"
          name="type"
        >
          <Radio.Group buttonStyle="solid" defaultValue="banner-and-image">
            <Radio.Button value="banner-and-image">
              Banner and Image
            </Radio.Button>
            <Radio.Button value="printer-friendly">
              Printer-Friendly
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Font" name="font">
          <Radio.Group buttonStyle="solid" defaultValue="Trebuchet-MS">
            <Radio.Button
              value="Trebuchet-MS"
              onClick={() => setPreviewFont("Trebuchet MS")}
            >
              Trebuchet MS
            </Radio.Button>
            <Radio.Button
              value="Roboto"
              onClick={() => setPreviewFont("Roboto")}
            >
              Roboto
            </Radio.Button>
            <Radio.Button
              value="Helvetica"
              onClick={() => setPreviewFont("Helvetica")}
            >
              Helvetica
            </Radio.Button>
            <Radio.Button value="Times" onClick={() => setPreviewFont("Times")}>
              Times
            </Radio.Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 40,
              }}
            >
              <p
                style={{ fontFamily: previewFont, fontSize: 20, lineHeight: 0 }}
              >
                This is what your font choice looks like.
              </p>
            </div>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreatePDFModal
