import React from "react"
import { Button } from "antd"
import { ControlOutlined } from "@ant-design/icons"
import "./TableSettingsButton.scss"

interface Props {
  onClick: () => void
}

const TableSettingsButton = ({ onClick }: Props) => {
  return (
    <Button
      className="table-settings__button"
      icon={<ControlOutlined />}
      size="large"
      type="primary"
      onClick={onClick}
    >
      Table Settings
    </Button>
  )
}

export default TableSettingsButton
