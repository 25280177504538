import React, { useState, useContext } from "react"
import { Modal, Form, Button, Checkbox } from "antd"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import "./NewProjectModal.scss"
import { State } from "../../context/State"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { Actions } from "../../context/reducer"

interface Props {
  visible: boolean
  setVisible: (value: boolean) => void
}

const TableSettingsModal = ({ visible, setVisible }: Props) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state: State = useContext<any>(GlobalStateContext)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setLoading(false)
    setVisible(false)
  }

  const onChange = (
    e: CheckboxChangeEvent,
    checkbox: "subtitle" | "rating" | "standard-deviation" | "responses"
  ) => {
    const { checked } = e.target
    dispatch({
      type: Actions.UpdateTableDisplay,
      table: {
        showSubtitle:
          checkbox === "subtitle" ? checked : state.table.showSubtitle,
        showRating: checkbox === "rating" ? checked : state.table.showRating,
        showStandardDeviation:
          checkbox === "standard-deviation"
            ? checked
            : state.table.showStandardDeviation,
        showResponses:
          checkbox === "responses" ? checked : state.table.showResponses,
      },
    })
  }

  return (
    <Modal
      title="Table Settings"
      centered
      visible={visible}
      onCancel={handleClose}
      footer={[
        <Button onClick={handleClose} key="done" type="primary">
          Done
        </Button>,
      ]}
    >
      <Form name="tableSettings" initialValues={state.table}>
        <Form.Item name="showSubtitle" valuePropName="checked">
          <Checkbox onChange={e => onChange(e, "subtitle")}>
            Show Author
          </Checkbox>
        </Form.Item>
        <Form.Item name="showRating" valuePropName="checked">
          <Checkbox onChange={e => onChange(e, "rating")}>Show Rating</Checkbox>
        </Form.Item>
        <Form.Item name="showStandardDeviation" valuePropName="checked">
          <Checkbox onChange={e => onChange(e, "standard-deviation")}>
            Show Standard Deviation
          </Checkbox>
        </Form.Item>
        <Form.Item name="showResponses" valuePropName="checked">
          <Checkbox onChange={e => onChange(e, "responses")}>
            Show Responses
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TableSettingsModal
