import React from "react"
import { Bar } from "react-chartjs-2"
import { Rating } from "../context/State"
import useWindowDimensions from "../helper/useWindowDimensions"
import { RatingParameters } from "./vote-form/VoteForm"

interface Props {
  ratings: Rating[]
  parameters: RatingParameters
}

const getLabels = (parameters: RatingParameters) => {
  const labels = []
  let labelValue = parameters.min
  while (labelValue <= parameters.max) {
    labels.push(Math.round(labelValue * 10) / 10)
    labelValue += parameters.step
  }
  return labels
}

const getData = (ratings: Rating[], labels: number[]) => {
  let data: number[] = []
  for (let i = 0; i < labels.length; i += 1) {
    data[i] = 0

    ratings.forEach(rating => {
      if (labels[i] === rating.value) {
        data[i] += 1
      }
    })
  }
  return data
}

const ResponsesChart = ({ ratings, parameters }: Props) => {
  const dimensions = useWindowDimensions()

  const labels = getLabels(parameters)
  const state = {
    labels,
    datasets: [
      {
        label: "Rating",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: getData(ratings, labels),
      },
    ],
  }

  const getHeight = () => {
    if (dimensions) {
      if (dimensions.width < 400) {
        return 150
      } else if (dimensions.width < 500) {
        return 200
      } else if (dimensions.width < 600) {
        return 250
      }
    }
    return 300
  }

  return (
    <div>
      <Bar
        data={state}
        options={{
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
        }}
        height={getHeight()}
      />
    </div>
  )
}

export default ResponsesChart
