import jsPDF from "jspdf"
import { HeaderTitle, HeaderContent } from "./constraints/Header"
import { Project } from "../../context/State"
import { PDFOptions } from "./exportProjectAsPDF"

const addHeaderTitle = (
  pdf: jsPDF,
  project: Project,
  imageWidth: number,
  options: PDFOptions
) => {
  pdf.setFontSize(HeaderTitle.size)
  pdf.setTextColor(options.type === "printer-friendly" ? "#000000" : "#ffffff")

  if (options.type === "printer-friendly") pdf.setFont(options.font, "bold")
  else pdf.setFont(options.font, "normal")

  const title = pdf.splitTextToSize(
    project.name,
    HeaderContent.end.x - HeaderTitle.start.getX(imageWidth)
  )
  pdf.text(
    title,
    HeaderTitle.start.getX(imageWidth),
    HeaderTitle.start.getY(
      pdf.getTextDimensions(title).h,
      imageWidth,
      options.type
    )
  )
}

export default addHeaderTitle
