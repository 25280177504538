import React, { useState } from "react"
import TableSettingsButton from "./TableSettingsButton"
import TableSettingsModal from "../modals/TableSettingsModal"

interface Props {}

const TableSettings = (props: Props) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <TableSettingsButton onClick={() => setModalVisible(true)} />
      <TableSettingsModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}

export default TableSettings
