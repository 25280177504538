const imageToBase64 = (
  img: Blob,
  isSVG: boolean,
  callback: (result: string | ArrayBuffer | null) => void
) => {
  if (typeof document === undefined) return
  const reader = new FileReader()
  reader.addEventListener("load", () => {
    if (isSVG) callback(btoa(reader.result as string))
    callback(reader.result)
  })
  reader.readAsDataURL(img)
}

export default imageToBase64
