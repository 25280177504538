import React, { useState, useContext } from "react"
import { List, Button, Tooltip, Modal } from "antd"
const { confirm } = Modal
import "./ProjectList.scss"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import { Link, navigate } from "gatsby"
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UserAddOutlined,
  FilePdfOutlined,
} from "@ant-design/icons"
import NewProjectModal from "../modals/NewProjectModal"
import ShareProjectModal from "../modals/ShareProjectModal"
import { Actions } from "../../context/reducer"
import { Project } from "../../context/State"
import useFirebase from "../../firebase/useFirebase"
import CreatePDFModal from "../modals/CreatePDFModal"

interface Props {
  projects: Project[]
}

const ProjectList = ({ projects }: Props) => {
  const firebase = useFirebase()
  const [newProjectModalVisible, setNewProjectModalVisible] = useState(false)
  const [shareProjectModalVisible, setShareProjectModalVisible] = useState(
    false
  )
  const [projectToShare, setProjectToShare] = useState<Project | undefined>()
  const [createPDFModalVisible, setCreatePDFModalVisible] = useState(false)
  const [projectForPDF, setProjectForPDF] = useState<Project | undefined>()
  const dispatch: any = useContext(GlobalDispatchContext)

  const confirmDeleteProject = (project: Project) => {
    confirm({
      title: "Are you sure you'd like to permanently delete this project?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      // @ts-ignore
      okType: "danger",
      onOk() {
        dispatch({ type: Actions.DeleteProject, key: project.key })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {projects.length > 0 ? (
        <div className="project-list">
          <List
            itemLayout="horizontal"
            dataSource={projects}
            renderItem={project => (
              <List.Item
                extra={
                  <div className="project-list__actions">
                    <Tooltip placement="top" title="Export as PDF">
                      <Button
                        icon={<FilePdfOutlined />}
                        onClick={() => {
                          setProjectForPDF(project)
                          setCreatePDFModalVisible(true)
                        }}
                      ></Button>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title="Share project"
                      className="project-list__action"
                    >
                      <Button
                        icon={<UserAddOutlined />}
                        onClick={() => {
                          setProjectToShare(project)
                          setShareProjectModalVisible(true)
                        }}
                      ></Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete project">
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => confirmDeleteProject(project)}
                      ></Button>
                    </Tooltip>
                  </div>
                }
              >
                <List.Item.Meta
                  className="project-list__project"
                  avatar={
                    <Link
                      className="project-list__project__image-background"
                      style={{ background: project.backgroundColor }}
                      to="dashboard"
                      onClick={() => {
                        dispatch({
                          type: Actions.SetSelectedProject,
                          project,
                        })
                      }}
                    >
                      {project.image ? (
                        <img
                          src={project.image as any}
                          className="project-list__project__image"
                        />
                      ) : (
                        <></>
                      )}
                    </Link>
                  }
                  title={
                    <span
                      className="project-list__project__title"
                      onClick={() => {
                        dispatch({
                          type: Actions.SetSelectedProject,
                          project,
                        })
                        navigate("dashboard")
                      }}
                    >
                      {project.name}
                    </span>
                  }
                />
              </List.Item>
            )}
          />
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate("/dashboard/new")}
            className="project-list__add"
          >
            New Project
          </Button>
        </div>
      ) : (
        <div className="project-list__no-projects">
          <h1>Looks like you don't have any projects. Let's create one!</h1>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate("/dashboard/new")}
          >
            New Project
          </Button>
        </div>
      )}
      <NewProjectModal
        visible={newProjectModalVisible}
        setVisible={setNewProjectModalVisible}
      />
      <ShareProjectModal
        visible={shareProjectModalVisible}
        setVisible={setShareProjectModalVisible}
        project={projectToShare}
      />
      <CreatePDFModal
        visible={createPDFModalVisible}
        setVisible={setCreatePDFModalVisible}
        project={projectForPDF}
      />
    </>
  )
}

export default ProjectList
