const PDF = {
  start: {
    x: 0,
    y: 0,
  },
  end: {
    x: 8.5,
    y: 11,
  },
  margin: 1,
}

export default PDF
