import React, { useState, useContext } from "react"
import { Modal, Form, Input, message, Button } from "antd"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import "./NewProjectModal.scss"
import useFirebase from "../../firebase/useFirebase"
import { Project, State } from "../../context/State"

interface Props {
  project: Project | undefined
  visible: boolean
  setVisible: (value: boolean) => void
}

const fieldsDefault = [{ name: ["email"], value: "" }]

const ShareProjectModal = ({ project, visible, setVisible }: Props) => {
  const dispatch: any = useContext(GlobalDispatchContext)
  const state: State = useContext<any>(GlobalStateContext)
  const firebase = useFirebase()
  const [fields, setFields] = useState(fieldsDefault)
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setFields(fieldsDefault)
    setLoading(false)
    setVisible(false)
  }

  const handleOk = async () => {
    const email = fields[0].value

    if (!firebase) return
    if (state.user && project) {
      setLoading(true)

      const shareProject = firebase.functions().httpsCallable("shareProject")
      if (shareProject) {
        shareProject({
          email,
          project: project?.key,
        }).then(result => {
          if (result.data.error) {
            setLoading(false)
            message.error(result.data.error, 7)
          } else {
            message.success("Success! Your project was shared.")
            setFields(fieldsDefault)
            setLoading(false)
            setVisible(false)
          }
        })
      } else console.log("Could not fetch function.")
    } else {
      message.error("Sorry, something went wrong. Try again in a moment.")
    }
  }

  return (
    <Modal
      title={`Share Project${project ? `: ${project.name}` : ""}`}
      centered
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Share
        </Button>,
      ]}
    >
      <Form
        name="add-poposal"
        fields={fields}
        onFieldsChange={(changedFields, allFields) => {
          setFields(allFields as any)
        }}
      >
        <Form.Item
          className="add-proposal__label"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input the email to share to.",
            },
          ]}
        >
          <Input className="add-proposal__field" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ShareProjectModal
