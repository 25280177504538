import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

interface Props {
  onClick: () => void
  type: "no-votes" | "has-votes"
}

const NewVoteButton = ({ onClick, type }: Props) => {
  if (type === "no-votes")
    return (
      <div className="project-list__no-projects">
        <h1>Welcome to your new project! Let's create a vote.</h1>
        <Button icon={<PlusOutlined />} type="primary" onClick={onClick}>
          New Vote
        </Button>
      </div>
    )
  else if (type === "has-votes")
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 30,
          background: "#ffffff",
        }}
      >
        <Button
          size="large"
          icon={<PlusOutlined />}
          type="primary"
          onClick={onClick}
        >
          New Vote
        </Button>
      </div>
    )
  else return <></>
}

export default NewVoteButton
