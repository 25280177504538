import PDF from "./PDF"

const Footer = {
  start: {
    x: PDF.start.x,
    y: PDF.end.y - PDF.margin,
  },
  end: {
    x: PDF.end.x,
    y: PDF.end.y,
  },
  padding: {
    x: PDF.margin,
    y: PDF.margin / 4,
  },
}

export const FooterContent = {
  start: {
    x: Footer.start.x + Footer.padding.x,
    y: Footer.start.y + Footer.padding.y,
  },
  end: {
    x: Footer.end.x - Footer.padding.x,
    y: Footer.end.y - Footer.padding.y,
  },
  center: {
    x: Footer.start.x + (Footer.end.x - Footer.start.x) / 2,
    y: Footer.start.y + (Footer.end.y - Footer.start.y) / 2,
  },
}

export default Footer
