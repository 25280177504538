import PDF from "./PDF"
import { PDFOptions } from "../exportProjectAsPDF"

const Header = {
  start: {
    x: PDF.start.x,
    y: PDF.start.y,
  },
  end: {
    x: PDF.end.x,
    y: PDF.margin,
  },
  padding: {
    x: PDF.margin,
    y: PDF.margin / 4,
  },
}

export const HeaderContent = {
  start: {
    x: Header.start.x + PDF.margin,
    y: Header.start.y,
  },
  end: {
    x: Header.end.x - PDF.margin,
    y: Header.end.y,
  },
}

export const HeaderImage = {
  x: Header.start.x + Header.padding.x,
  y: Header.start.y + Header.padding.y,
  height: PDF.margin - Header.padding.y * 2,
}

export let HeaderTitle = {
  size: 18,
  color: "#ffffff",
  padding: Header.padding.y,
  start: {
    getX: (imageWidth: number) => 0,
    getY: (textHeight: number, imageWidth: number, type: PDFOptions["type"]) =>
      0,
  },
}

HeaderTitle.start.getX = (imageWidth: number) => {
  if (imageWidth === 0) return HeaderContent.start.x
  return HeaderImage.x + imageWidth + HeaderTitle.padding
}

HeaderTitle.start.getY = (
  textHeight: number,
  imageWidth: number,
  type: PDFOptions["type"]
) => {
  if (type === "printer-friendly") return HeaderContent.end.y
  else {
    return (
      HeaderContent.start.y +
      (HeaderContent.start.y + HeaderContent.end.y) / 2 +
      textHeight / 3
    )
  }
}

export default Header
