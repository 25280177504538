import React, { useState, useContext } from "react"
import { Modal, Form, Input, message, Button } from "antd"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { Actions } from "../../context/reducer"
import { TwitterPicker } from "react-color"
import { v4 } from "uuid"
import ImageUpload, { ImageFile } from "../ImageUpload"
import "./NewProjectModal.scss"
import { State, Project } from "../../context/State"

interface Props {
  visible: boolean
  setVisible: (value: boolean) => void
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const fieldsDefault = [{ name: ["name"], value: "" }]
const defaultColor = "#0693E3"

const NewProjectModal = ({ visible, setVisible }: Props) => {
  const dispatch: any = useContext(GlobalDispatchContext)
  const state: State = useContext<any>(GlobalStateContext)
  const [fields, setFields] = useState(fieldsDefault)
  const [color, setColor] = useState(defaultColor)
  const [image, setImage] = useState<any>()
  const [imageBlob, setImageBlob] = useState<any>()
  const [loading, setLoading] = useState(false)

  const handleColorChange = (color: { hex: string }) => {
    setColor(color.hex)
  }

  const handleCancel = () => {
    setFields(fieldsDefault)
    setImage(null)
    setImageBlob(null)
    setLoading(false)
    setVisible(false)
  }

  const handleOk = async () => {
    if (state.user) {
      setLoading(true)
      console.log("Sending dispatch")
      const project: Project = {
        key: v4(),
        name: fields[0].value,
        creatorUid: state.user.uid,
        users: [state.user.uid],
        backgroundColor: color,
      }

      await dispatch({ type: Actions.AddProject, project, image: imageBlob })
      message.success("Success! Your new project was created.")
      setFields(fieldsDefault)
      setImage(null)
      setImageBlob(null)
      setLoading(false)
      setVisible(false)
    } else {
      message.error("Sorry, something went wrong. Try again in a moment.")
    }
  }

  return (
    <Modal
      title="New Project"
      centered
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Create
        </Button>,
      ]}
    >
      <h3 className="new-project__subheader">Project Details</h3>
      <Form
        {...layout}
        name="add-poposal"
        fields={fields}
        onFieldsChange={(changedFields, allFields) => {
          setFields(allFields as any)
        }}
      >
        <Form.Item
          className="add-proposal__label"
          label="Project Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a project name.",
            },
          ]}
        >
          <Input className="add-proposal__field" />
        </Form.Item>
        <Form.Item
          className="add-proposal__label"
          label="Banner Image"
          name="image"
        >
          <ImageUpload
            imageUrl={image}
            setImageUrl={setImage}
            setImageBlob={setImageBlob}
          />
        </Form.Item>
      </Form>
      <h3 className="new-project__subheader">Project Banner Preview</h3>
      <div
        style={{ background: color }}
        className="new-project__banner-preview"
      >
        {image ? <img src={image} alt={"Project image"} /> : <></>}
        <h2>{fields[0].value}</h2>
      </div>
      <TwitterPicker
        color={color}
        onChange={color => handleColorChange(color)}
      />
    </Modal>
  )
}

export default NewProjectModal
