import React, { useContext, Dispatch } from "react"
import { TableData, VoteEvent, Project, State } from "../../context/State"
import CopyToClipboard from "react-copy-to-clipboard"
import { Tooltip, Button, message, Modal } from "antd"
const { confirm } = Modal
import {
  LinkOutlined,
  ClearOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import { Actions, ActionProps } from "../../context/reducer"
import { ColumnsType } from "antd/lib/table"
import { SortOrder } from "antd/lib/table/interface"

const checkNoResponses = (a: TableData, b: TableData, sortOrder: SortOrder) => {
  if (sortOrder === "ascend") {
    if (a.responses > 0 && b.responses === 0) return -1000
    if (a.responses === 0 && b.responses > 0) return 1000
  } else if (sortOrder === "descend") {
    if (a.responses > 0 && b.responses === 0) return 1000
    if (a.responses === 0 && b.responses > 0) return -1000
  }
  return null
}

const confirmClearResponses = (proposal: VoteEvent, dispatch: any) => {
  confirm({
    title: "Are you sure you'd like to clear the responses for this proposal?",
    icon: <ExclamationCircleOutlined />,
    content: "",
    okText: "Clear",
    // @ts-ignore
    okType: "danger",
    onOk() {
      dispatch({ type: Actions.ClearResponses, key: proposal.key })
    },
    onCancel() {},
  })
}

const confirmDeleteResponses = (vote: VoteEvent, dispatch: any) => {
  confirm({
    title: "Are you sure you'd like to permanently delete this vote?",
    icon: <ExclamationCircleOutlined />,
    content: "",
    okText: "Delete",
    // @ts-ignore
    okType: "danger",
    onOk() {
      dispatch({ type: Actions.DeleteVoteEvent, key: vote.key })
    },
    onCancel() {},
  })
}

const getColumns = (
  windowIsSmall: boolean,
  selectedProject: Project | null,
  state: State,
  dispatch: (action: ActionProps) => Promise<void>
): ColumnsType<TableData> => [
  {
    title: "Proposal",
    dataIndex: "name",
    key: "name",
    sorter: (a: TableData, b: TableData) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return -1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return 1
      return 0
    },
    sortDirections: ["descend", "ascend"],
  },
  state.table.showSubtitle
    ? {
        title: "Author",
        dataIndex: "author",
        key: "author",
        sorter: (a: TableData, b: TableData) => {
          if (a.author.toLowerCase() > b.author.toLowerCase()) return -1
          if (a.author.toLowerCase() < b.author.toLowerCase()) return 1
          return 0
        },
        sortDirections: ["descend", "ascend"],
        responsive: ["lg"],
      }
    : {},
  state.table.showRating
    ? {
        title: "Rating",
        dataIndex: "rating",
        key: "rating",
        sorter: (a: TableData, b: TableData, sortOrder) => {
          const result = checkNoResponses(a, b, sortOrder as SortOrder)
          if (result) return result
          return a.rating - b.rating
        },
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: "ascend",
      }
    : {},
  state.table.showStandardDeviation
    ? {
        title: "σ",
        dataIndex: "standardDeviation",
        key: "standardDeviation",
        sorter: (a: TableData, b: TableData, sortOrder) => {
          const result = checkNoResponses(a, b, sortOrder as SortOrder)
          if (result) return result
          return a.standardDeviation - b.standardDeviation
        },
        sortDirections: ["descend", "ascend"],
      }
    : {},
  state.table.showResponses
    ? {
        title: "Responses",
        dataIndex: "responses",
        key: "responses",
        sorter: (a: TableData, b: TableData) =>
          a.ratings.length - b.ratings.length,
        sortDirections: ["descend", "ascend"],
        responsive: ["sm"],
      }
    : {},
  {
    title: "Actions",
    key: "action",
    responsive: ["sm"],
    render: (text: string, record: TableData) => (
      <span className="table__actions">
        <CopyToClipboard
          // @ts-ignore
          className="table__actions__button--first"
          text={
            typeof window !== undefined
              ? window.location.origin +
                `/vote/?project=${selectedProject?.key}&key=${record.key}`
              : ""
          }
        >
          <Tooltip
            placement={windowIsSmall ? "left" : "top"}
            title="Copy voting link"
          >
            <Button
              onClick={() =>
                message.success("Success! Voting link copied to clipboard.")
              }
              shape="circle"
              icon={<LinkOutlined />}
            ></Button>
          </Tooltip>
        </CopyToClipboard>
        <Tooltip
          placement={windowIsSmall ? "left" : "top"}
          title="Clear responses"
        >
          <Button
            className="table__actions__button"
            shape="circle"
            onClick={() => confirmClearResponses(record, dispatch)}
            icon={<ClearOutlined />}
          ></Button>
        </Tooltip>
        <Tooltip
          placement={windowIsSmall ? "left" : "top"}
          title="Delete proposal"
        >
          <Button
            className="table__actions__button--last"
            shape="circle"
            onClick={() => confirmDeleteResponses(record, dispatch)}
            icon={<DeleteOutlined />}
          ></Button>
        </Tooltip>
      </span>
    ),
  },
]

export default getColumns
