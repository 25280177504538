import React, { useContext, useState, useEffect } from "react"
import "./Table.scss"
import {
  GlobalDispatchContext,
  GlobalStateContext,
  DispatchType,
} from "../../context/GlobalContextProvider"
import { Table } from "antd"
import useWindowDimensions from "../../helper/useWindowDimensions"
import { Actions } from "../../context/reducer"
import { useCollection } from "react-firebase-hooks/firestore"
import useFirebase from "../../firebase/useFirebase"
import NewVoteModal from "../modals/NewVoteModal"
import { State, VoteEvent } from "../../context/State"
import getColumns from "./TableColumns"
import TableExpandedRow from "./TableExpandedRow"
import SpinLoading from "../loading/SpinLoading"
import NewVoteButton from "./NewVoteButton"
import useURLVariables from "../../helper/useURLVariables"

interface Props {}

const TableComponent = (props: Props) => {
  const firebase = useFirebase()
  const state: State = useContext<any>(GlobalStateContext)
  const dispatch: DispatchType = useContext(GlobalDispatchContext)
  const dimensions = useWindowDimensions()
  const [windowIsSmall, setWindowIsSmall] = useState(false)
  const [loadingVotes, setLoadingVotes] = useState(true)
  const [newVoteModalVisible, setNewVoteModalVisible] = useState(false)

  const variables = useURLVariables()
  const urlKey = variables.key

  const documentKey = state.selectedProject
    ? state.selectedProject.key
    : urlKey || "error"

  const [snapshot, loading, error] = useCollection(
    firebase && state.user
      ? firebase
          .firestore()
          .collection("projects")
          .doc(documentKey)
          .collection("proposals")
      : null
  )

  useEffect(() => {
    const updateData = async () => {
      if (!loading && firebase && snapshot) {
        const voteEvents = snapshot.docs.map(doc => {
          return doc.data()
        }) as VoteEvent[]
        await dispatch({
          type: Actions.UpdateVoteEvents,
          voteEvents,
        })
        console.log(voteEvents)
        if (loadingVotes) setLoadingVotes(false)
      }
    }

    updateData()
  }, [snapshot, loading, error])

  useEffect(() => {
    if (!dimensions) return
    if (dimensions.width > 768 && windowIsSmall) {
      setWindowIsSmall(false)
    } else if (dimensions.width <= 768 && !windowIsSmall) {
      setWindowIsSmall(true)
    }
  }, [dimensions])

  if (loadingVotes) return <SpinLoading type="fullscreen" />

  return (
    <>
      {state.tableData.length > 0 ? (
        <>
          <Table
            columns={getColumns(
              windowIsSmall,
              state.selectedProject,
              state,
              dispatch
            )}
            expandable={{
              expandedRowRender: record => (
                <TableExpandedRow
                  record={record}
                  sidebarIsCollapsed={state.sidebar.isCollapsed}
                />
              ),
              rowExpandable: record => record.ratings.length > 0,
            }}
            dataSource={state.tableData}
            pagination={false}
            loading={loading}
          />
          <NewVoteButton
            type="has-votes"
            onClick={() => setNewVoteModalVisible(true)}
          />
        </>
      ) : (
        <NewVoteButton
          type="no-votes"
          onClick={() => setNewVoteModalVisible(true)}
        />
      )}
      <NewVoteModal
        visible={newVoteModalVisible}
        setVisible={setNewVoteModalVisible}
      />
    </>
  )
}

export default TableComponent
