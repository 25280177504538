import React, { useContext } from "react"
import { TableData, NameInput, VoteEvent } from "../../context/State"
import { Tabs, Popconfirm, Statistic, Table } from "antd"
import ResponsesChart from "../ResponsesChart"
import { CloseOutlined } from "@ant-design/icons"
import { Actions } from "../../context/reducer"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import { standardDeviation } from "simple-statistics"
const { TabPane } = Tabs

interface Props {
  record: TableData
  sidebarIsCollapsed: boolean
}

const TableExpandedRow = ({ record, sidebarIsCollapsed }: Props) => {
  const dispatch: any = useContext(GlobalDispatchContext)

  const deleteByName = (name: NameInput, voteEvent: VoteEvent) => {
    dispatch({ type: Actions.DeleteResponseByName, name, voteEvent })
  }

  return (
    <Tabs
      defaultActiveKey="1"
      className={`table__tab ${
        sidebarIsCollapsed ? "table__tab--collapsed" : ""
      }`}
    >
      <TabPane tab="Statistics" key="1">
        <ResponsesChart
          ratings={record.ratings}
          parameters={{
            min: 1,
            max: 9,
            step: 1,
          }}
        />
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Statistic title="Mean" value={record.rating} />
          <Statistic
            style={{
              marginLeft: 20,
            }}
            title="Standard Deviation"
            value={standardDeviation(
              record.ratings.map(rating => rating.value)
            )}
            precision={5}
          />
        </div>
      </TabPane>
      <TabPane tab={`Respondents (${record.responses})`} key="2">
        <div className="table__tab-respondents">
          {record.names ? (
            record.names
              .sort((a, b) => {
                // if (a.value < b.value) return -1
                // if (a.value > b.value) return 1
                // return 0

                if (a.timestamp < b.timestamp) return -1
                if (a.timestamp > b.timestamp) return 1
                return 0
              })
              .map(name => {
                return (
                  <Popconfirm
                    key={name.timestamp}
                    title={`Delete ${name.value}'s response?`}
                    onConfirm={() => deleteByName(name, record)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span className="table__tab-respondents__container">
                      <span className="table__tab-respondents__name">
                        {name.value}
                      </span>
                      <span>{new Date(name.timestamp).toLocaleString()}</span>
                      <CloseOutlined className="table__tab-respondents__delete" />
                    </span>
                  </Popconfirm>
                )
              })
          ) : (
            <p>No names have been recorded.</p>
          )}
        </div>
      </TabPane>
    </Tabs>
  )
}

export default TableExpandedRow
